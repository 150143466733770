body {
  background-color:hsl(48, 36%, 86%);
}

.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

#react-org-chart {
  margin: 0;
  cursor: move;
  position: absolute;
  height: 80%;
  width: 100%;
}

.zoom-buttons {
  position: absolute;
  top: 0;
  left: 0;
}

.download-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.zoom-button {
  width: 40px;
  height: 40px;
  display: flex !important;
  margin: 8px;
}


.org-chart-person-name {
  font-weight: 500;
}
.org-chart-person-link:hover g {
  fill: #409cf9 !important;
}
.org-chart-node:hover .org-chart-person-reports {
  fill: #409cf9 !important;
}
.org-chart-person-dept.engineering {
  fill: #4caf50 !important;
}
.org-chart-person-dept.communications {
  fill: #3f51b5 !important;
}
.org-chart-person-dept.product {
  fill: #d500f9 !important;
}
.org-chart-person-dept.hr {
  fill: #2196f3 !important;
}
.org-chart-person-dept.marketing {
  fill: #f44336 !important;
}
.org-chart-person-dept.design {
  fill: #26c6da !important;
}

.btn {
  font-size: 0.875rem;
  text-transform: none;
  text-decoration-line: none;
  display: inline-block;
  font-weight: 600;;
  vertical-align: middle;
  user-select: none;
  padding: 0.3rem 0.6rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  margin: 6px;
}

.btn-outline-primary {
  color: #374ea2;
  border-color: red;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #374ea2;
  border-color: red;
}

.github-link {
  font-size: 16px;
  margin-left: 8px;
  margin-right: 16px;
}